import React from 'react'
import { Helmet } from "react-helmet";
import Pricing from '../components/Common/Pricing/Pricing'
import Comparison from '../components/Common/Pricing/Comparison/Comparison'
import Layout from '../components/layout'

export default () => {
    return(
        <Layout>
            <Pricing faq comparison={true}/>
        </Layout>
    )
}